var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "depart_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择部门", clearable: "" },
                      model: {
                        value: _vm.form.depart_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depart_id", $$v)
                        },
                        expression: "form.depart_id",
                      },
                    },
                    _vm._l(_vm.department, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { clearable: "", placeholder: "请选择收款人" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.userdata, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.user_name, value: item.user_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "order_staff_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { clearable: "", placeholder: "请选择业务员" },
                      model: {
                        value: _vm.form.order_staff_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "order_staff_id", $$v)
                        },
                        expression: "form.order_staff_id",
                      },
                    },
                    _vm._l(_vm.staffSelect, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "pay_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "收款方式", clearable: "" },
                      model: {
                        value: _vm.form.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pay_type", $$v)
                        },
                        expression: "form.pay_type",
                      },
                    },
                    _vm._l(_vm.supplier, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bill_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "类型", clearable: "" },
                      model: {
                        value: _vm.form.bill_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bill_type", $$v)
                        },
                        expression: "form.bill_type",
                      },
                    },
                    _vm._l(_vm.Customertype, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bill_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "状态", clearable: "" },
                      model: {
                        value: _vm.form.bill_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bill_status", $$v)
                        },
                        expression: "form.bill_status",
                      },
                    },
                    _vm._l(_vm.status, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "print_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择打印状态", clearable: "" },
                      model: {
                        value: _vm.form.print_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "print_status", $$v)
                        },
                        expression: "form.print_status",
                      },
                    },
                    _vm._l(_vm.Typesof, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "Time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      clearable: "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.Time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "Time", $$v)
                      },
                      expression: "form.Time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单据号", prop: "bill_code" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "收款原始单据号" },
                    model: {
                      value: _vm.form.bill_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bill_code", $$v)
                      },
                      expression: "form.bill_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "客户名称、简拼、电话",
                    },
                    model: {
                      value: _vm.form.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword", $$v)
                      },
                      expression: "form.keyword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlersver },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v("重 置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerAdd },
                    },
                    [_vm._v("添 加")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.handlerExport } }, [
                    _vm._v("导 出"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { align: "center", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          row.bill_code == "合计"
                            ? _c("div")
                            : _c("div", [_vm._v(_vm._s($index + 1))]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.colemd, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            list.label == "原始单据"
                              ? _c("div", [
                                  row.order_ori_type == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "underline",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckOrder(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row[list.prop]) + " "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row[list.prop])),
                                      ]),
                                ])
                              : list.label == "收款方式"
                              ? _c("div", [
                                  _vm._v(
                                    " " + _vm._s(row[list.prop].join(",")) + " "
                                  ),
                                ])
                              : _c("div", [_vm._v(_vm._s(row[list.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "220",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.bill_status == 1,
                                expression: "row.bill_status == 1",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerinfo(row)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleprint(row)
                              },
                            },
                          },
                          [_vm._v(" 打印 ")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  row.bill_status == 1 &&
                                  _vm.authbutton[7].is_check == 1,
                                expression:
                                  "row.bill_status == 1 && authbutton[7].is_check == 1",
                              },
                            ],
                            attrs: { title: "您真的要作废这个单据吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.handlerstorp(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 作废 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-receipt", { ref: "add", on: { getlist: _vm.getlist } }),
      _c("info-receipt", { ref: "link", on: { getlist: _vm.getlist } }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "SK", data_id: _vm.data_id, type: 1 },
      }),
      _c("sale-order", { ref: "saleOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }