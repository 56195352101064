<!--
 * @Author: your name
 * @Date: 2021-02-07 15:12:33
 * @LastEditTime: 2021-03-26 10:36:24
 * @LastEditors: Please set LastEditors
 * @Description: 客户收款单
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\receipt\index.vue
-->
<template>
  <!-- NAME[epic=财务] 客户收款单 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="depart_id">
          <el-select
            v-model="form.depart_id"
            placeholder="请选择部门"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in department"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user_id">
          <el-select
            v-model="form.user_id"
            clearable
            placeholder="请选择收款人"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in userdata"
              :key="index"
              :label="item.user_name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="order_staff_id">
          <el-select
            v-model="form.order_staff_id"
            clearable
            placeholder="请选择业务员"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in staffSelect"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="pay_type">
          <el-select
            v-model="form.pay_type"
            placeholder="收款方式"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bill_type">
          <el-select
            v-model="form.bill_type"
            placeholder="类型"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Customertype"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bill_status">
          <el-select
            v-model="form.bill_status"
            placeholder="状态"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="print_status">
          <el-select
            v-model="form.print_status"
            placeholder="请选择打印状态"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="daterange"
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="单据号" prop="bill_code">
          <el-input
            v-model="form.bill_code"
            clearable
            placeholder="收款原始单据号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="keyword">
          <el-input
            v-model="form.keyword"
            clearable
            placeholder="客户名称、简拼、电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerAdd">添 加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        show-summary
        :summary-method="summaryFunction"
      >
        <el-table-column align="center" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div v-if="list.label == '原始单据'">
              <span
                v-if="row.order_ori_type == 1"
                style="text-decoration: underline; cursor: pointer"
                @click="handleCheckOrder(row)"
              >
                {{ row[list.prop] }}
              </span>
              <span v-else>{{ row[list.prop] }}</span>
            </div>
            <div v-else-if="list.label == '收款方式'">
              {{ row[list.prop].join(',') }}
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
        <!-- 1待对账2已对账3已作废4已冲改 -->
        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template #default="{ row }">
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-button
              v-show="row.bill_status == 1"
              type="text"
              @click="handlerinfo(row)"
            >
              修改
            </el-button>
            <!-- <el-button
              v-show="row.bill_status == 2"
              type="text"
              @click="handlerredo(row)"
            >
              冲改
            </el-button> -->
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handleprint(row)"
            >
              打印
            </el-button>
            <el-popconfirm
              v-show="row.bill_status == 1 && authbutton[7].is_check == 1"
              title="您真的要作废这个单据吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                作废
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-receipt ref="add" @getlist="getlist"></add-receipt>
    <info-receipt ref="link" @getlist="getlist"></info-receipt>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="SK"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <sale-order ref="saleOrder"></sale-order>
  </div>
</template>

<script>
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { dateFormat } from '@/utils/Time'
  import InfoReceipt from './compontents/InfoReceipt.vue'
  import AddReceipt from './compontents/AddReceipt.vue'
  import timefort from '@/utils/timestamp'
  import { downloadFile, postAction } from '@/api/Employee'
  import { getStaffList } from '@/api/setPrice'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder'
  import { getSelect } from '@/api/saleOrder'
  import dayjs from "dayjs";

  export default {
    components: {
      AddReceipt,
      InfoReceipt,
      RuilangDisplayDialog,
      saleOrder,
    },
    data() {
      return {
        data_id: 0,
        totalCollect: '',
        form: {
          depart_id: '', //部门ID
          user_id: '', //员工ID
          pay_type: '', //收款方式ID
          bill_type: '', //收款类型  1销售2派送3预存款4预存货5清欠收款6退货转存货7退货转清欠8预存退款9陈列补款
          bill_status: '', //状态 1待对账2已对账3已作废4已冲改
          print_status: '', //打印状态 1已打印2未打印
          keyword: '', //店铺名称\简拼等
          bill_code: '', //收款单原始单据号
          Time: [dayjs(new Date()).format('YYYY-MM-DD') , dayjs(new Date()).format('YYYY-MM-DD')],
          start_time: dateFormat(new Date()), //开始时间 如2020-12-22
          end_time: dateFormat(new Date()), //结束时间 如2020-12-22
          pageSize: 10,
          pageNo: 1,
        },
        total: 0,
        staffSelect: [],
        userdata: [],
        Typesof: [
          {
            id: '2',
            name: '未打印',
          },
          {
            id: '1',
            name: '已打印',
          },
        ],
        //1销售2派送3预存款4预存货5清欠收款6退货转存货7退货转清欠8预存退款9陈列补款
        Customertype: [
          {
            id: '1',
            name: '销售',
          },
          {
            id: '2',
            name: '派送',
          },
          {
            id: '3',
            name: '预存款',
          },
          {
            id: '4',
            name: '预存货',
          },
          {
            id: '5',
            name: '清欠收款',
          },
          {
            id: '6',
            name: '退货转存货',
          },
          {
            id: '7',
            name: '退货转清欠',
          },
          {
            id: '8',
            name: '预存退款',
          },
          {
            id: '9',
            name: '陈列补款',
          },
        ],
        //1待对账2已对账3已作废4已冲改
        status: [
          {
            id: '1',
            name: '待对账',
          },
          {
            id: '2',
            name: '已对账',
          },
          {
            id: '3',
            name: '已作废',
          },
          {
            id: '4',
            name: '已冲改',
          },
        ],
        supplier: [],
        department: [],
        url: {
          department: '/baseAdmin/depart/index',
          userlist: '/baseAdmin/common/user-list',
          list: '/cashAdmin/cash/cash-list',
          supplier: '/promoteAdmin/deposit-order/pay-type', //支付方式
          stop: '/cashAdmin/cash/cash-scrap',
        },
        tableData: [],
        checkList: [
          '部门',
          '收款人',
          '客户名称',
          '收款单号',
          '原始单据',
          '类型',
          '金额',
          '收款方式',
          '状态',
          '收款时间',
          '备注',
          '打印数',
        ],
        colemd: [
          {
            order: 1,
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '收款人',
            align: 'center',
            prop: 'add_name',
            width: '',
          },
          {
            order: 3,
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 4,
            label: '收款单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 5,
            label: '原始单据',
            align: 'center',
            prop: 'order_code',
            width: '',
          },
          {
            order: 12,
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            order: 6,
            label: '类型',
            align: 'center',
            prop: 'bill_type_text',
            width: '',
          },

          {
            order: 7,
            label: '金额',
            align: 'center',
            prop: 'bill_amount',
            width: '',
          },
          {
            order: 8,
            label: '收款方式',
            align: 'center',
            prop: 'pay_type',
            width: '',
          },
          {
            order: 9,
            label: '状态',
            align: 'center',
            prop: 'bill_status_text',
            width: '',
          },
          {
            order: 9.5,
            label: '收款时间',
            align: 'center',
            prop: 'add_time',
            width: '',
          },
          {
            order: 10,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
          {
            order: 11,
            label: '打印数',
            align: 'center',
            prop: 'print_times',
            width: '',
          },
        ],
        footer: {},
        authbutton: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
          console.log(this.form.start_time)
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      'form.goodsname'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    created() {
      getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      this.form.start_time = dateFormat(new Date())
      this.form.end_time = dateFormat(new Date())
      getStaffList().then((r) => {
        this.staffSelect = r.data
      })
    },
    mounted() {
      this.hanlderuserlist()
      this.handlerlist()
      this.handlersupplier()
      this.handlerdepartment()
    },
    methods: {
      handlerredo(row) {
        this.$refs.link.showtit = 3
        this.$refs.link.title = '冲改收款单'
        this.$refs.link.receipt = true
        this.$refs.link.handlerData(row)
      },
      handlerlink(row) {
        this.$refs.link.receipt = true
        this.$refs.link.handlerData(row)
      },
      handlerinfo(row) {
        this.$refs.link.showtit = 2
        this.$refs.link.receipt = true
        this.$refs.link.handlerData(row)
      },
      handlerAdd() {
        this.$refs.add.receipt = true
      },
      handleprint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        let sums = []
        sums[0] = '总计'
        columns.forEach((list, index) => {
          if (list.label == '金额') {
            sums[index] = this.totalCollect
          }
          // if (list.label == '未结算金额') {
          //   sums[index] = this.out_total_amount
          // }
        })
        return sums
      },
      getlist() {
        this.handlerlist()
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款方式
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '收款方式')
            this.supplier = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id }).then((res) => {
          console.log(res, '作废')
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.handlerlist()
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.rows
            this.totalCollect = res.data.totalCollect
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerExport() {
        downloadFile(
          '/cashAdmin/cash/cash-list-export',
          '客户收款单.xlsx',
          this.form
        )
      },
      handleCheckOrder(row) {
        this.$refs.saleOrder.id = row.order_ori_id
        this.$refs.saleOrder.isshowDialog = true
      },
    },
  }
</script>

<style></style>
