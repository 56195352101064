<!--
 * @Author: your name
 * @Date: 2021-02-18 15:59:01
 * @LastEditTime: 2021-03-29 15:57:36
 * @LastEditors: Please set LastEditors
 * @Description: 添加收款单
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\receipt\compontents\AddReceipt.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="receipt"
      title="添加收款单"
      center
      width="70%"
    >
      <div>
        <el-row>
          <el-col :span="10">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="客户名称" prop="client">
                <client-search
                  ref="clientSearch"
                  :w="250"
                  @select-val-alldata="selectBrnad"
                ></client-search>
                <!-- <el-select
                  v-model="form.client_id"
                  placeholder="客户名称"
                  style="width: 250px"
                >
                  <el-option
                    v-for="list in client"
                    :key="list.user_id"
                    :label="list.cust_name"
                    :value="list.id"
                  ></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="收款人" prop="user_id">
                <el-select
                  v-model="form.user_id"
                  placeholder="收款人"
                  style="width: 250px"
                >
                  <el-option
                    v-for="list in userdata"
                    :key="list.user_id"
                    :label="list.user_name"
                    :value="list.user_id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="收款顺序" prop="clear_type">
                <el-select
                  v-model="form.clear_type"
                  placeholder="收款顺序"
                  style="width: 250px"
                >
                  <el-option
                    v-for="list in order"
                    :key="list.id"
                    :label="list.name"
                    :value="list.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="应收款">
                {{ money }}
              </el-form-item>
              <el-form-item label="实收款" prop="pay_data">
                <el-row>
                  <el-col v-for="(item, index) in form.pay_data" :key="index">
                    <el-input
                      v-model="item.money"
                      placeholder="金额"
                      style="width: 100px"
                    ></el-input>
                    <el-select
                      v-model="item.pay_type"
                      class="dwei"
                      placeholder="请选择收款方式"
                      style="width: 120px; margin-left: 10px"
                    >
                      <el-option
                        v-for="list in account"
                        :key="list.id"
                        :label="list.name"
                        :value="list.id"
                      ></el-option>
                    </el-select>
                    <i
                      class="el-icon-circle-plus-outline"
                      style="font-size: 20px"
                      @click="handleraddpay"
                    ></i>
                  </el-col>
                  <!-- <el-col>
                    <i
                      class="el-icon-circle-plus-outline"
                      style="font-size: 20px"
                      @click="handleraddpay"
                    ></i>
                  </el-col> -->
                </el-row>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  placeholder="备注"
                  style="width: 250px"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="14">
            <el-table
              ref="table"
              stripe
              :data="tableData"
              :row-key="getRowKey"
              height="300"
              @selection-change="handlerSelection"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
              ></el-table-column>
              <el-table-column
                v-for="(item, index) in celund"
                :key="index"
                :label="item.label"
                :align="item.align"
                :prop="item.prop"
              >
                <template #default="{ row }">
                  <div v-if="item.label == '类型'">
                    <span v-show="row.order_ori_type == 1">销售单</span>
                    <span v-show="row.order_ori_type == 2">销售订单</span>
                    <span v-show="row.order_ori_type == 3">预存款</span>
                    <span v-show="row.order_ori_type == 4">预存货</span>
                  </div>
                  <div v-else-if="item.label == '单号'">
                    <span class="uderline" @click="handleCheckOrder(row)">
                      {{ row[item.prop] }}
                    </span>
                  </div>
                  <div v-else>
                    {{ row[item.prop] }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="form.pageNo"
              :layout="layout"
              :page-size="form.pageSize"
              :total="total"
              background
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button @click="receipt = !receipt">关 闭</el-button>
      </div>
      <sale-order ref="saleOrder"></sale-order>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder'
  import ClientSearch from '@/baseComponents/clientSearch'
  export default {
    components: { ClientSearch, saleOrder },
    data() {
      return {
        receipt: false,
        url: {
          userlist: '/baseAdmin/common/user-list',
          client: 'baseAdmin/customer-info/index',
          account: '/promoteAdmin/deposit-order/pay-type',
          save: '/cashAdmin/cash/cash-add',
        },
        form: {
          pageSize: 10,
          pageNo: 1,
          client_id: '',
          arrears_ids: '', //欠款单ID   如[5,6,7]
          user_id: '', //收款人ID
          clear_type: '', //收款类型 1时间由远及近 2由近及远
          pay_data: [{ pay_type: '', money: '' }], //收款数据 如:[{"pay_type":1,"money":500},{"pay_type":1,"money":500}]
          remark: '', //备注
        },
        client: [],
        userdata: [],
        tableData: [],
        account: [],
        celund: [
          {
            label: '类型',
            align: 'center',
            prop: 'bill_type_text',
          },
          {
            label: '单号',
            align: 'center',
            prop: 'order_ori_code',
          },
          {
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
          },
          {
            label: '待收款',
            align: 'center',
            prop: 'qian_money',
          },
        ],
        order: [
          {
            id: 1,
            name: '时间由远及近',
          },
          {
            id: 2,
            name: '时间由近及远',
          },
        ],
        total: 0,
        page: {
          pageSize: 10,
          pageNo: 1,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        summe: [],
      }
    },
    computed: {
      money() {
        var sum = 0
        this.summe.forEach((list) => {
          sum += Number(list.qian_money)
        })
        return sum.toFixed(2)
      },
    },
    watch: {
      // tableData(val) {
      //   console.log(val, '变化')
      //   this.summe = []
      //   this.$refs.table.clearSelection()
      // },
      receipt(val) {
        if (!val) {
          this.form = {
            pageSize: 10,
            pageNo: 1,
            client_id: '',
            arrears_ids: '', //欠款单ID   如[5,6,7]
            user_id: '', //收款人ID
            clear_type: '', //收款类型 1时间由远及近 2由近及远
            pay_data: [{ pay_type: '', money: '' }], //收款数据 如:[{"pay_type":1,"money":500},{"pay_type":1,"money":500}]
            remark: '', //备注
          }
          this.tableData = []
          this.summe = []
          this.$refs.clientSearch.resetForm()
          this.$refs.table.clearSelection()
        }
      },
      'form.client_id'(val) {
        if (val) {
          console.log(val, '客户id')
          this.fetchData(val)
        }
      },
    },
    mounted() {
      this.hanlderuserlist()
      this.handlerclient()
      this.handleraccount()
    },
    methods: {
      fetchData(val = '') {
        postAction('/cashAdmin/cash/arrears-list', {
          ...{ cust_id: val },
          ...this.page,
        })
          .then((res) => {
            console.log('客户欠款记录', res)
            this.tableData = res.data.rows
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlersave() {
        if (this.summe.length > 0) {
          const data = JSON.parse(JSON.stringify(this.form))
          delete data.client_id
          let a = []
          this.summe.forEach((list) => {
            console.log(list.id)
            a.push(list.id)
          })
          data.arrears_ids = JSON.stringify(a)
          data.pay_data = JSON.stringify(data.pay_data)
          postAction(this.url.save, data)
            .then((res) => {
              console.log(res, '保存')
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.$emit('getlist')
              this.receipt = false
            })
            .catch((err) => {
              console.log(err, '')
            })
        } else {
          if (this.form.user_id != '') {
            this.$message({
              message: '请先选欠款单',
              type: 'error',
            })
          } else {
            this.$message({
              message: '请先选择客户',
              type: 'error',
            })
          }
        }
      },
      handleraddpay() {
        this.form.pay_data.push({ pay_type: '', money: '' })
      },
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.summe = val
      },
      getRowKey(row) {
        return row.id
      },
      selectBrnad(val) {
        this.form.client_id = val.id
      },
      // 收款账户
      handleraccount() {
        postAction(this.url.account, { pageSize: -1, status: 1 })
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //客户
      handlerclient() {
        postAction(this.url.client, { pageSize: -1 })
          .then((res) => {
            console.log('客户', res)
            this.client = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, { pageSize: -1 })
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleCurrentChange(val) {
        this.page.pageNo = val
        this.fetchData(this.form.client_id)
      },
      handleSizeChange(val) {
        this.page.pageSize = val
        this.fetchData(this.form.client_id)
      },
      handleCheckOrder(row) {
        this.$refs.saleOrder.id = row.order_ori_id
        this.$refs.saleOrder.isshowDialog = true
      },
    },
  }
</script>

<style>
  /* .dwei {
    position: absolute;
  }
  .zding {
    position: relative;
    top: -30px;
    right: 200px;
  } */
</style>
