var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            visible: _vm.receipt,
            title: "添加收款单",
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.receipt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: { model: _vm.form, "label-width": "80px" },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户名称", prop: "client" } },
                            [
                              _c("client-search", {
                                ref: "clientSearch",
                                attrs: { w: 250 },
                                on: { "select-val-alldata": _vm.selectBrnad },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人", prop: "user_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "250px" },
                                  attrs: { placeholder: "收款人" },
                                  model: {
                                    value: _vm.form.user_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "user_id", $$v)
                                    },
                                    expression: "form.user_id",
                                  },
                                },
                                _vm._l(_vm.userdata, function (list) {
                                  return _c("el-option", {
                                    key: list.user_id,
                                    attrs: {
                                      label: list.user_name,
                                      value: list.user_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "收款顺序", prop: "clear_type" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "250px" },
                                  attrs: { placeholder: "收款顺序" },
                                  model: {
                                    value: _vm.form.clear_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "clear_type", $$v)
                                    },
                                    expression: "form.clear_type",
                                  },
                                },
                                _vm._l(_vm.order, function (list) {
                                  return _c("el-option", {
                                    key: list.id,
                                    attrs: { label: list.name, value: list.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "应收款" } }, [
                            _vm._v(" " + _vm._s(_vm.money) + " "),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "实收款", prop: "pay_data" } },
                            [
                              _c(
                                "el-row",
                                _vm._l(
                                  _vm.form.pay_data,
                                  function (item, index) {
                                    return _c(
                                      "el-col",
                                      { key: index },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          attrs: { placeholder: "金额" },
                                          model: {
                                            value: item.money,
                                            callback: function ($$v) {
                                              _vm.$set(item, "money", $$v)
                                            },
                                            expression: "item.money",
                                          },
                                        }),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "dwei",
                                            staticStyle: {
                                              width: "120px",
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              placeholder: "请选择收款方式",
                                            },
                                            model: {
                                              value: item.pay_type,
                                              callback: function ($$v) {
                                                _vm.$set(item, "pay_type", $$v)
                                              },
                                              expression: "item.pay_type",
                                            },
                                          },
                                          _vm._l(_vm.account, function (list) {
                                            return _c("el-option", {
                                              key: list.id,
                                              attrs: {
                                                label: list.name,
                                                value: list.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus-outline",
                                          staticStyle: { "font-size": "20px" },
                                          on: { click: _vm.handleraddpay },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  type: "textarea",
                                  placeholder: "备注",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "table",
                          attrs: {
                            stripe: "",
                            data: _vm.tableData,
                            "row-key": _vm.getRowKey,
                            height: "300",
                          },
                          on: { "selection-change": _vm.handlerSelection },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              "reserve-selection": true,
                            },
                          }),
                          _vm._l(_vm.celund, function (item, index) {
                            return _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.label,
                                align: item.align,
                                prop: item.prop,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        item.label == "类型"
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        row.order_ori_type == 1,
                                                      expression:
                                                        "row.order_ori_type == 1",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("销售单")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        row.order_ori_type == 2,
                                                      expression:
                                                        "row.order_ori_type == 2",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("销售订单")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        row.order_ori_type == 3,
                                                      expression:
                                                        "row.order_ori_type == 3",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("预存款")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        row.order_ori_type == 4,
                                                      expression:
                                                        "row.order_ori_type == 4",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("预存货")]
                                              ),
                                            ])
                                          : item.label == "单号"
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "uderline",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCheckOrder(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(row[item.prop]) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row[item.prop]) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                        ],
                        2
                      ),
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.form.pageNo,
                          layout: _vm.layout,
                          "page-size": _vm.form.pageSize,
                          total: _vm.total,
                          background: "",
                        },
                        on: {
                          "current-change": _vm.handleCurrentChange,
                          "size-change": _vm.handleSizeChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.receipt = !_vm.receipt
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
          _c("sale-order", { ref: "saleOrder" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }